import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import schedule from "../images/schedule.svg"
import notes from "../images/notes.svg"
import share from "../images/share.svg"

const SignUpPage = () => {
  return (
    <Layout>
      <SEO title="Sign up" />
      <div className="hero">
        <div className="hero-body has-text-centered">
          <h1 className="title is-family-secondary">Sign up</h1>
        </div>
      </div>
      <div className="column is-paddingless has-background-primary">
        <div className="section has-text-centered">
          <h2 className="title is-family-secondary has-text-black">
            How it works <span className="is-underlined">for mentors</span>
          </h2>
        </div>
        <div className="section">
          <div className="columns has-text-black">
            <div className="column has-text-centered">
              <div className="container">
                <div className="container mm-icon">
                  <img src={schedule} alt="Illustration of a schedule" />
                </div>
                <div className="container">
                  <p className="content">
                    First, set up your{" "}
                    <a
                      href="https://calendly.com/"
                      className="has-text-black is-underlined"
                    >
                      Calendly account
                    </a>{" "}
                    to show your availability. We recommend at least 30 minutes
                    slots. Pick your favourite platform to use for your
                    sessions.
                  </p>
                </div>
              </div>
            </div>
            <div className="column has-text-centered">
              <div className="container">
                <div className="mm-icon">
                  <img src={notes} alt="Illustration of a form" />
                </div>
                <div className="container">
                  <p className="content">
                    Fill out your details in the mentors form.
                  </p>
                </div>
              </div>
            </div>
            <div className="column has-text-centered">
              <div className="container">
                <div className="container mm-icon">
                  <img src={share} alt="Illustration of a megaphone" />
                </div>
                <div className="container">
                  <p className="content">Share the news with your network!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        title="Signup form"
        className="airtable-embed"
        src="https://airtable.com/embed/shrENK8Y3EKx574EY?backgroundColor=purple"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="600"
        style={{ background: "transparent" }}
      ></iframe>
    </Layout>
  )
}

export default SignUpPage
